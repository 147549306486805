<template>
    <section class="border-bottom">
        <div class="row mt-4 mb-2 justify-center">
            <div class="col-12 col-xl-10">
                <label class="f-15 text-muted2">
                    Seleccionar el estado de  {{ pais }}
                </label>
                <el-select v-model="id_estado" class="w-100" filterable remote reserve-keyword placeholder="Seleccione o digíte un estado" :remote-method="remoteMethodEstado" :loading="loading" @change="ingresarEstado($event)">
                    <el-option v-for="item in estados" :key="item.id" :label="item.estado" :value="item.id" :disabled="item.disabled" />
                </el-select>
            </div>
        </div>
        <div class="col-12">
            <div class="row mx-0 px-3">
                <div v-for="(item, idx) in guardados" :key="idx" class="bg-gr-red px-2 br-12 d-middle-center text-white my-2 mx-1" style="height:32px;">
                    {{ item.estado }} <i class="icon-cancel cr-pointer" @click="eliminarEstadoCascada(item)" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import {mapGetters} from 'vuex'

export default {
    props:{
        idPais:{
            type: Number,
            default: 0
        },
        pais:{
            type: String,
            default: ''
        }
    },
    data(){
        return{
            estados: [],
            loading: false,
            guardados: [],
            id_estado: null
        }
    },
    computed:{
        ...mapGetters({
            id_proyecto: 'proyectos/proyectos/id_proyecto',
            detalle: 'proyectos/proyectos/detalle',
        })
    },
    mounted(){
        this.listarEstadosGuardados()
        this.listar_estados()
    },
    methods:{
        async remoteMethodEstado(texto){
            this.delay(this.listar_estados(texto))
        },
        async listarEstadosGuardados(){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    id_pais: this.idPais 
                }
                const {data} = await Proyectos.listarEstadosProyecto(params)
                this.guardados = data.estados
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_estados(texto= null){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    texto,
                    id_pais: this.idPais 

                }
                this.loading = true
                const {data} = await Proyectos.buscarEstados(params)
                this.loading = false
                this.estados = data.estados.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async ingresarEstado(e){
            try {
                const enviar = {
                    id_proyecto: this.id_proyecto,
                    id_estado:  this.id_estado 
                }
                const {data} = await Proyectos.ingresarEstado(enviar)
                this.notificacion('Mensaje', 'Estado ingresado', 'success')
                this.listarEstadosGuardados()
                this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto: this.id_proyecto})
                this.listar_estados()


            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarEstadoCascada(item){
            try {
                const {data} = await Proyectos.eliminarEstadoCascada(item.id_proyecto, this.detalle.cobertura)
                this.notificacion('Mensaje', 'Lugar eliminado', 'success')
                await this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto: this.id_proyecto})
                this.listarEstadosGuardados()
                this.listar_estados()


            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>